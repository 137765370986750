.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  a{
    display: block;
    color: $secondary;
    line-height: 2rem;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .seperator{
    display: none;
    color: $secondary;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.footer-gold{
  background: $primary;
}