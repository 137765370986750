
.goldenbackground{
  background: $quaternary_light;
  overflow-x: hidden;
  @include media-breakpoint-down(xs) {
    width: 110%;
  }
  .row{
    overflow-x: hidden;
  }
}

.hintergrundbild{
  *{
    color: #ffffff !important;
  }
  .vc_btn3{
    color: $tertiary !important;
  }
}

.svg200 img{
  width: 200px;
}

.newsletterbox{
  ul{
    list-style: none;

    li{
      display: inline-flex;
      align-items: end;
    }
    li:before{
        content:'';
        display:inline-block;
        height:36px;
        width: 45px;
        background-image:url("images/icons/haken.svg");
        background-size:45px;
        background-repeat:no-repeat;
        padding-left: 2em;
        margin-left: -39px;
    }
  }
}

//ACCODION STYLING
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body{
 background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_general {
  .vc_tta-panel-heading{
    border-radius: 0px !important;
    .vc_tta-title-text{
      font-size: 16*$px-base;
      font-weight: bold;
    }
    *{
      color: $primary !important;
      border-color: $primary !important;
    }
  }
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-controls-icon::after, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-controls-icon::before{
  border-color: $primary !important;
}
html body.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon{
 top: 59%;
}