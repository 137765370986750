
.admin-bar .hauptnavigation{
  top: 0px;
}
@include media-breakpoint-only(md) {
  #search {
    margin-right: 85px;
  }
}
.hauptnavigation{
  width: 100%;
  height: calc(100% - 92px);
  z-index: 500;
  background-color: $secondary;
  top: 0px;

  .hauptnavigation-container{
    position: relative;
    @include media-breakpoint-down(md) {
      .ubermenu-responsive-toggle{
        position: absolute;
        top: -119px;
        right: 120px !important;
        overflow: hidden;
        height: 30px;
        padding: 0px;
        margin: 0px;
        width: 50px;
        color: $secondary;
        .fa-bars:before {
          font-size: 30px;
        }
      }
      @media (min-width: 576px){
        .ubermenu-responsive-toggle {
          top: -139px;
        }
      }
      @media (max-width: 400px){
        .ubermenu-responsive-toggle {
          right: 60px !important;
        }
      }
    }
    @include media-breakpoint-only(md) {
      .ubermenu-responsive-toggle {
        right: 15px;
        top: -160px;
        height: 60px;
        font-size: 0.75rem;
        margin-top: 1px;
        .fa-bars{
          margin-bottom: 12px;
        }
        .fa-bars:before {
          font-size: 25px;
        }
      }
    }
  }

  .menu-hauptmenue-container{
    ul {
      width: 100%;
      list-style: none;
      text-align: left;
      display: block;
      padding-left: 15px;

      &.sub-menu{
        position: relative;
        display: none;
      }

      li{
        .navarrow {
          cursor: pointer;
          display: none;
        }
        .navarrowmobil{
          cursor: pointer;
          display: inline-block;
          position:absolute;
          right: 0;
          top: 0;
        }

        a{
          text-decoration: none;
          padding-right: 20px;
          text-transform: uppercase;
        }

        &.current-page-parent > a, &.current-page-ancestor > a, &.current_page_item > a{
          text-decoration: underline;
        }

      }

      li.hover > .sub-menu {
        display: block;
      }
      li.hover > a {
        text-decoration: underline;
      }
    }
  }

  .hauptnavigation-footer-container{
    ul{
      list-style: none;
      text-align: left;
      padding-left: 15px;
      li{
        a{
          display: block;
          color: $secondary;
          text-transform: uppercase;
        }
      }
    }
  }

  .menu-hauptmenue-container{
    *{
      color: $secondary;
      position: relative;
    }

    a{
      color: $secondary;

      padding: 8px 0px;
      display: inline-block;
      text-wrap: break-word;
    }
  }
}
.ubermenu .ubermenu-submenu-type-flyout>.ubermenu-item{
  min-width: 360px !important;
}
.ubermenu-main .ubermenu-item-normal.ubermenu-current-page-parent > a,
.ubermenu-main .ubermenu-item-normal.ubermenu-current-page-ancestor > a,
.ubermenu-main .ubermenu-has-submenu-drop.ubermenu-current-page-parent > .ubermenu-target > .ubermenu-sub-indicator,
.ubermenu-main .ubermenu-has-submenu-drop.ubermenu-current-page-ancestor > .ubermenu-target > .ubermenu-sub-indicator{

  color: #002438 !important;
}
.ubermenu-main .ubermenu-item-normal.ubermenu-current-page-parent > a:hover{
  color: #002438 !important;
}

@media (min-width: 426px) {
  .hauptnavigation{

    top: 92px;
    background-color: transparent;
  }
  .admin-bar .hauptnavigation{
    top: 124px;
  }
  .slimheader {

  }
}
@media (max-width: 768px) {
  .hauptnavigation .sub-menu{
    height: auto !important;
  }
  .menu-hauptmenue-container a{
    word-break: break-all;
  }
}

@media (min-width: 768px) {
  .hauptnavigation {

    .menu-hauptmenue-container{

      ul {
        display: block;
        position: relative;
        width: 25%;

        top: 0;
        list-style: none;
        padding-left: 15px;

        &.sub-menu {
          left: 100%;
          width: 100%;
          display: none;
          position: absolute;
          background-color: #234354;

          .sub-menu {
            background-color: #556d7b;

            .sub-menu {
              background-color: #798c96;
            }
          }
        }

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: initial;
          a {
            font-size: 14*$px-base;

          }

          .navarrow {
            display: inline-block;
          }
          .navarrowmobil{
            display: none;
          }
        }
      }
    }
    .hauptnavigation-footer-container {
      ul {
        list-style: none;
        text-align: center;
        display: flex;
        justify-content: center;
        li a{
          padding: 15px 30px;
        }
      }
    }
  }
}

@media (min-width: 1023px) {
  .hauptnavigation {
    .menu-hauptmenue-container {
      ul {
        padding-left: 40px;
        padding-top: 30px;
        li a {
          font-size: 16*$px-base;
          text-transform: uppercase;
        }
      }
    }
  }
}